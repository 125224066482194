
import QuestWrapperComponent from "../../components/QuestWrapperComponent.vue";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import Vue, { PropType } from "vue";
import confetti from "canvas-confetti";
import { mapMutations, mapGetters } from "vuex";

export default Vue.extend({
  name: "visit-complete",
  props: {
    q: Object as PropType<QuestViewModel>,
    isAfterCheckout: { default: false, type: Boolean },
  },
  data() {
    return {
      isLogoShown: true as boolean,
    };
  },
  mounted() {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
    setTimeout(() => {
      this.isLogoShown = false;
    }, 1900);
  },
  computed: {
    ...mapGetters({
      Loading: "getLoading",
      Content: "getContent",
      QuestType: "getCurrentQuestType",
    }),
    // completeText() {
    //   const defaultText = "Default text when VisitCompleteText is not available";
    //   if (this.q.visitRequired || this.q.noDrReview) {
    //     return "Be sure to check your email for updates.";
    //   } else {
    //     return (this.Content.VisitCompleteText as string) || defaultText;
    //   }
    // },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
    }),
    next_click() {
      this.setLoading(true);
      this.$emit("saveandnavigate", "+");

      setTimeout(() => {
        this.setLoading(false);
      }, 3000);
    },
  },
  components: { QuestWrapperComponent },
});
