export enum ScreenEnum {
  Error = -1,
  BasicInfo = 1,
  NotYet = 2,
  ShippingPaymentInfo = 4,
  IdentityUpload = 5,
  VisitComplete = 6,
  Questions = 7,
  ChargeConfirmation = 8,
  ScheduleVisit = 9,
  OTCCart = 10,
  ChooseMedication = 11,
  ChooseQuantity = 12,
  ChooseShipping = 13,
  ProductReview = 14,
  SignUp = 15,
  RefillChargeConfirmation = 16,
  RefillComplete = 17,
  PhotosUpdateComplete = 18,
  ChargeComplete = 19,
  RxReview = 20,
  SubscriptionStart = 21,
  SubscriptionChoices = 22,
  SubscriptionCheckout = 23,
  Checkout = 24,
  GetStarted = 25,
  Modify = 26,
  QuestionsAfterCheckout = 27,
}
