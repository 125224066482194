
import Vue, { PropType } from "vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import { OTCProductViewModel } from "@/interfaces/OTCProductViewModel";
import axios from "axios";
import store from "../store/index";
import router from "../router";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { PaymentViewModel } from "@/interfaces/PaymentViewModel";
import swal from "sweetalert";
import { ShoppingCartItem } from "@/interfaces/ShoppingCartItem";
import ShoppingCartItemComponent from "./ShoppingCartItemComponent.vue";

export default Vue.extend({
  name: "upsell-component",
  props: {
    q: Object as PropType<QuestViewModel> | PropType<PaymentViewModel>,
  },
  data() {
    return {
      loadingProductID: null as number | null,
    };
  },
  mounted() {
    if (this.Content.DefaultSelectFirstUpsell && (!this.q.quest.shoppingCartItems || this.q.quest.shoppingCartItems.length == 0)) {
      this.AddToCart(this.getOTCProducts[0]);
    }
  },
  computed: {
    ...mapGetters({
      Affiliate: "getAffiliate",
      Theme: "getTheme",
      Content: "getContent",
      FirstName: "getFirstName",
      getOTCProducts: "getOTCProducts",
      QuestType: "getCurrentQuestType",
    }),
    selectedOption(): string | null {
      const productText = this.q.quest.shoppingCartItems && this.q.quest.shoppingCartItems.length > 0 ? this.q.quest.shoppingCartItems.map((x) => x.productName).join(" & ") : "";
      return productText ? `Add ${productText}` : "Select a product";
    },
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setQuestionnaire: "setQuestionnaire",
    }),
    isLoading(productID: number): boolean {
      return this.loadingProductID == productID ? true : false;
    },
    isSelected(productID: number): boolean {
      let i = this.q.quest.shoppingCartItems && this.q.quest.shoppingCartItems.length > 0 ? this.q.quest.shoppingCartItems.find((x) => x.productID == productID) : null;
      return i ? true : false;
    },
    toggle(si: OTCProductViewModel) {
      if (this.isSelected(si.id)) {
        this.RemoveFromCart(si);
      } else {
        this.AddToCart(si);
      }
    },
    async AddToCart(si: OTCProductViewModel) {
      var q = Object.assign({}, this.q);
      let i = q.quest.shoppingCartItems && q.quest.shoppingCartItems.length > 0 ? q.quest.shoppingCartItems.find((x) => x.productID == si.id) : null;
      if (i) {
        i.qty = 1;
        i.productPrice = si.pricePerMinPurchaseQty;
        i.affiliateMarkupPercent = 1;
        i.subTotal = si.pricePerMinPurchaseQty;
        i.productName = si.name;
        i.productID = si.id;
        i.productTypeID = 0;
        i.active = true;
      } else {
        i = {
          id: null,
          qty: 1,
          productPrice: si.pricePerMinPurchaseQty,
          affiliateMarkupPercent: 1,
          subTotal: si.pricePerMinPurchaseQty,
          productName: si.name,
          productID: si.id,
          productTypeID: 0,
          createdDate: null,
          modifiedDate: null,
          active: true,
          trackingNumber: null,
          shoppingCartID: null,
          trackingNumberReceivedDatetime: null,
          shippingCarrierID: null,
        };
      }
      this.loadingProductID = si.id;
      const rsp = await store.dispatch("SaveShoppingCartItemForQuest", { items: [i], t: this.QuestType });
      if (rsp && rsp.cart.items && rsp.cart.items.length > 0) {
        q.quest.shoppingCartItems = [];
        q.quest.shoppingCartItems.splice(0);
        rsp.cart.items.forEach((ri: ShoppingCartItem) => {
          q.quest.shoppingCartItems.push(ri);
        });
        this.setQuestionnaire({ q: q, t: this.QuestType });
      } else {
        swal("", "", "error");
      }
      this.loadingProductID = null;
    },
    async RemoveFromCart(si: OTCProductViewModel) {
      var q = Object.assign({}, this.q);
      let i = q.quest.shoppingCartItems && q.quest.shoppingCartItems.length > 0 ? q.quest.shoppingCartItems.find((x) => x.productID == si.id) : null;
      if (i) {
        i.active = false;
        i.qty = 0;
        this.loadingProductID = si.id;
        const rsp = await store.dispatch("SaveShoppingCartItemForQuest", { items: q.quest.shoppingCartItems, t: this.QuestType });
        if (rsp && rsp.cart) {
          q.quest.shoppingCartItems = [];
          q.quest.shoppingCartItems.splice(0);
          rsp.cart.items.forEach((ri: ShoppingCartItem) => {
            q.quest.shoppingCartItems.push(ri);
          });
          this.setQuestionnaire({ q: q, t: this.QuestType });
        } else {
          swal("", "", "error");
        }
        this.loadingProductID = null;
      }
    },
    async RemoveAllFromCart() {
      var q = Object.assign({}, this.q);
      if (q.quest.shoppingCartItems && q.quest.shoppingCartItems.length > 0) {
        q.quest.shoppingCartItems.forEach((x) => {
          x.active = false;
          x.qty = 0;
        });
        this.setLoading(true);
        const rsp = await store.dispatch("SaveShoppingCartItemForQuest", { items: q.quest.shoppingCartItems, t: this.QuestType });
        if (rsp && rsp.cart) {
          q.quest.shoppingCartItems = [];
          q.quest.shoppingCartItems.splice(0);
          rsp.cart.items.forEach((ri: ShoppingCartItem) => {
            q.quest.shoppingCartItems.push(ri);
          });
          this.setQuestionnaire({ q: q, t: this.QuestType });
        } else {
          swal("", "", "error");
        }
        this.setLoading(false);
      }
      this.closeModal();
    },
    closeModal() {
      window.postMessage({ messageType: "CloseUpsellModal" }, "*");
    },
  },
});
