
import { BCol, BContainer, BRow } from "bootstrap-vue";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { QuestViewModel } from "@/interfaces/QuestViewModel";
import { ScreenEnum } from "@/interfaces/ScreenEnum";
import { StatusIDEnum } from "@/interfaces/StatusIDEnum";
import store from "@/store/index";
import router from "@/router";
import moment from "moment";
import { AjaxReturnViewModel } from "@/interfaces/AjaxReturnViewModel";
import { AffiliateProductRx } from "@/interfaces/AffiliateProductRx";
import { QuestionnaireProductRx } from "@/interfaces/QuestionnaireProductRx";
import { ProductRxSubscriptionFrequencyViewModel } from "@/interfaces/ProductRxSubscriptionFrequencyViewModel";
import { QuestionnaireViewModel } from "@/interfaces/QuestionnaireViewModel";
import { VisitType } from "@/interfaces/VisitType";

export default Vue.extend({
  props: {
    outsidePromo: { type: String, default: null },
    productRxID: { type: String, default: null },
    productRxSubscriptionFrequencyID: { type: String, default: null },
    tryScreenForce: { type: String, default: null },
  },
  data() {
    return {
      placeholder: false,
      screen: 0 as number,
      selectedProductSubscriptionAvailable: false as boolean,
      selectedFrequency: null as ProductRxSubscriptionFrequencyViewModel | null,
      adjustedDoctorConsult: 0 as number,
      programFee: 0 as number,
      medTotal: 0 as number,
      totalPrice: 0 as number,
      queryProductRxSubscriptionFrequencyID: "" as string,
      queryProductRxID: "" as string,
      isUnsupportedState: false as boolean,
      isAfterCheckout: false as boolean,
    };
  },
  watch: {
    screen(newVal) {
      if (this.q) this.q.screen = this.screen;
    },
  },
  computed: {
    ...mapGetters({
      Content: "getContent",
      Questionnaires: "getQuestionnaires",
      SignedIn: "getSignedIn",
      QuestType: "getCurrentQuestType",
      IsQuickLink: "getIsVerifiedQuickLink",
      IsQuickLinkUser: "getIsQuickLinkUser",
    }),
    q(): QuestViewModel {
      return this.Questionnaires[this.QuestType];
    },
    underAge(): boolean {
      return moment().diff(moment(this.q.patient.dateOfBirth, "YYYY=MM-DD"), "years", true) < 18;
    },
  },
  async created() {
    this.queryProductRxSubscriptionFrequencyID = this.productRxSubscriptionFrequencyID;
    this.queryProductRxID = this.productRxID;

    if (!this.QuestType) {
      router.push("/"); // Got here without picking a condition
      return;
    }
    var arVM: AjaxReturnViewModel<QuestViewModel> = await store.dispatch("GetStarted", { t: this.QuestType });
    var isGetStarted = arVM && arVM.data ? arVM.data.chooseProductPriorToAuth : false;

    var questSetFromQuery = await this.setProductFromQuery();
    if (questSetFromQuery) {
      if (this.SignedIn) {
        arVM = await store.dispatch("GetQuestionnaire", { t: this.QuestType });
        arVM.data.quest.qProductRxs = questSetFromQuery.qProductRxs;
        await store.dispatch("SaveQuest", { q: arVM.data.quest, t: this.QuestType });
      } else {
        arVM.data.quest.qProductRxs = questSetFromQuery.qProductRxs;
        this.setQuestionnaire({ q: arVM.data, t: this.QuestType });
      }
      isGetStarted = false;
    }

    if (!this.SignedIn) {
      console.log("QuestionnaireView", this.q);
      if (isGetStarted) this.setScreen(ScreenEnum.GetStarted);
      else if (router.currentRoute.name != "/questionnaire/signup") this.setScreen(ScreenEnum.SignUp);
      else this.setScreen(ScreenEnum.SignUp, true);
      return;
    }
    if (this.IsQuickLink) {
      this.setIsVerifiedQuickLink(false);
      console.log(`quick link cleared`);
    }
    this.init(isGetStarted);
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setPatient: "setPatient",
      setQuestionnaire: "setQuestionnaire",
      setIsVerifiedQuickLink: "setIsVerifiedQuickLink",
    }),
    async setProductFromQuery(): Promise<QuestionnaireViewModel | null> {
      if (this.productRxID != null) {
        var freqs = [] as ProductRxSubscriptionFrequencyViewModel[];
        var q = Object.assign({}, this.q.quest);
        var qProductRx = q.qProductRxs && q.qProductRxs.length > 0 ? q.qProductRxs[0] : ({} as QuestionnaireProductRx);
        const p = this.q.products.filter((x: AffiliateProductRx) => x.id == this.productRxID);
        if (p && p.length > 0) {
          if (this.productRxSubscriptionFrequencyID && parseInt(this.productRxSubscriptionFrequencyID) > 0) freqs = await this.getFreqsByProductID(p[0].id);
          var filteredFreqs = freqs.filter((x: ProductRxSubscriptionFrequencyViewModel) => x.id == this.productRxSubscriptionFrequencyID);
          if (filteredFreqs && filteredFreqs.length > 0) {
            this.selectedFrequency = filteredFreqs[0];
          }
          qProductRx.productRxID = p[0].id;
          qProductRx.productRxGroupID = p[0].productRxGroupID;
          qProductRx.active = true;
          qProductRx.fillSubscriptionFrequencyDays = this.selectedFrequency ? this.selectedFrequency.fillSubscriptionFrequencyDays : null;
          qProductRx.chargeSubscriptionFrequencyDays = this.selectedFrequency ? this.selectedFrequency.chargeSubscriptionFrequencyDays : null;
          qProductRx.numberOfSubscriptionChargesRequired = this.selectedFrequency ? this.selectedFrequency.numberOfSubscriptionChargesRequired : null;
          qProductRx.isSubscription = this.selectedFrequency ? true : false;
          q.qProductRxs = [qProductRx];
          return q;
        }
        return null;
      }
      return null;
    },
    ensureScreen() {
      var s = this.screen;
      switch (router.currentRoute.name) {
        case "/questionnaire/basicinfo":
          s = this.setScreen(ScreenEnum.BasicInfo, true);
          break;
        case "/questionnaire/questions":
          s = this.setScreen(ScreenEnum.Questions, true);
          break;
        case "/questionnaire/shippingpaymentinfo":
          s = this.setScreen(ScreenEnum.ShippingPaymentInfo, true);
          break;
        case "/questionnaire/error":
          s = this.setScreen(ScreenEnum.Error, true);
          break;
        case "/questionnaire/choosemedication":
          s = this.setScreen(ScreenEnum.ChooseMedication, true);
          break;
        case "/questionnaire/choosequantity":
          s = this.setScreen(ScreenEnum.ChooseQuantity, true);
          break;
        case "/questionnaire/chooseshipping":
          s = this.setScreen(ScreenEnum.ChooseShipping, true);
          break;
        case "/questionnaire/productreview":
          s = this.setScreen(ScreenEnum.ProductReview, true);
          break;
        case "/questionnaire/identityupload":
          s = this.setScreen(ScreenEnum.IdentityUpload, true);
          break;
        case "/questionnaire/visitcomplete":
          s = this.setScreen(ScreenEnum.VisitComplete, true);
          break;
        case "/questionnaire/chargeconfirmation":
          s = this.setScreen(ScreenEnum.ChargeConfirmation, true);
          break;
        case "/questionnaire/schedulevisit":
          s = this.setScreen(ScreenEnum.ScheduleVisit, true);
          break;
        case "/questionnaire/signup":
          s = this.setScreen(ScreenEnum.SignUp, true);
          break;
        case "/questionnaire/checkout":
          s = this.setScreen(ScreenEnum.Checkout, true);
          break;
        case "/questionnaire/getstarted":
          s = this.setScreen(ScreenEnum.GetStarted, true);
          break;
        case "/questionnaire/aftercheckoutquestionnaire":
          s = this.setScreen(ScreenEnum.QuestionsAfterCheckout, true);
          break;
        case "/questionnaire/modify":
          s = this.setScreen(ScreenEnum.Modify, true);
          break;
        default:
          break;
      }
      return s;
    },
    async init(isGetStarted: boolean) {
      this.setLoading(true);
      var arVM: AjaxReturnViewModel<QuestViewModel> = await store.dispatch("GetQuestionnaire", { t: this.QuestType });
      if (arVM.redirectRoute && arVM.redirectRoute.length > 0) {
        router.push(arVM.redirectRoute);
        this.setLoading(false);
        return;
      }
      var q: QuestViewModel = arVM.data;
      if (!q || !q.quest) {
        router.push("/questionnaire/error");
        this.setLoading(false);
        return;
      }
      if (q.quest.statusID == StatusIDEnum.AwaitingAfterCheckoutQuestions) {
        this.setScreen(ScreenEnum.QuestionsAfterCheckout);
        this.setLoading(false);
        return;
      }
      if (q.quest.statusID == StatusIDEnum.AwaitingVisitScheduling) {
        this.setScreen(ScreenEnum.ScheduleVisit);
        this.setLoading(false);
        return;
      }
      if (this.q.quest.hardStop) {
        this.setScreen(ScreenEnum.NotYet);
        this.setLoading(false);
        return;
      }
      if (q.quest.statusID != StatusIDEnum.Incomplete && q.quest.statusID != StatusIDEnum.AwaitingAfterCheckoutQuestions) {
        router.push("/patientportal");
        this.setLoading(false);
        return;
      }
      if (this.tryScreenForce) {
        switch (this.tryScreenForce) {
          case "24":
            if (this.q.patient.photo1Path && this.q.patient.photo2Path) this.setScreen(ScreenEnum.Checkout);
            break;

          default:
            break;
        }
      }
      this.ensureScreen();
      if (router.currentRoute.name == "questionnaire") this.pageLoadNavigate(isGetStarted);
      this.q.visitRequired = this.q.quest.visitRequired;
      this.setLoading(false);
    },
    async navigate(dir: string, lastQuestionScreen?: number | null, forceScreenChange?: boolean | null, skipShipping?: boolean | null, fromCheckout?: boolean | null) {
      console.log(`this.screen ${this.screen}`);
      console.log(`dir ${dir}`);
      console.log(`forceScreenChange ${forceScreenChange}`);
      console.log(`lastQuestionScreen ${lastQuestionScreen}`);
      console.log(`skipShipping ${skipShipping}`);
      if (this.q.quest.hardStop) {
        this.setScreen(ScreenEnum.NotYet);
        this.setLoading(false);
        return;
      }
      if (this.q.errors && this.q.errors.length > 0) {
        this.setScreen(ScreenEnum.Error);
        this.setLoading(false);
        return;
      }
      switch (this.screen) {
        case ScreenEnum.GetStarted:
          if (dir === "+") {
            if (this.SignedIn) {
              if (this.q.getStartedProductsAfterQuestionnaire || fromCheckout) this.setScreen(ScreenEnum.Checkout);
              else this.setScreen(ScreenEnum.BasicInfo);
            } else this.setScreen(ScreenEnum.SignUp);
          } else {
            if (this.q.getStartedProductsAfterQuestionnaire)
              if (this.q.photoEntryAfterCheckout) this.setScreen(ScreenEnum.Questions);
              else this.setScreen(ScreenEnum.IdentityUpload);
          }
          break;
        case ScreenEnum.SignUp:
          if (dir === "+") this.setScreen(ScreenEnum.BasicInfo);
          break;
        case ScreenEnum.BasicInfo:
          if (dir === "+") {
            var stateSupported = await store.dispatch("CheckSupportedState", { state: this.q.patient.state, p: this.q.patient, q: this.q.quest, t: this.QuestType });
            this.isUnsupportedState = !stateSupported;
            if ((this.q.hardStopFemale && this.q.patient.gender !== "m") || !stateSupported || this.underAge) {
              this.setScreen(ScreenEnum.NotYet);
            } else {
              if (this.q.disableProductSelection || this.q.chooseProductPriorToAuth || this.q.getStartedProductsAfterQuestionnaire) {
                this.setScreen(ScreenEnum.Questions);
              } else {
                if (this.q.isSingleProductGroup) {
                  this.setScreen(ScreenEnum.ChooseQuantity);
                } else {
                  this.setScreen(ScreenEnum.ChooseMedication);
                }
              }
            }
          } else {
            if (this.q.chooseProductPriorToAuth) this.setScreen(ScreenEnum.GetStarted);
          }
          break;
        case ScreenEnum.ShippingPaymentInfo:
          if (dir === "+") this.setScreen(ScreenEnum.ChargeConfirmation);
          else {
            if (this.q.noIdentityVerification || this.q.isRenewal) this.setScreen(ScreenEnum.Questions);
            else this.setScreen(ScreenEnum.IdentityUpload);
          }
          break;
        case ScreenEnum.Questions:
          if (dir === "+") {
            if (!forceScreenChange && lastQuestionScreen !== null) {
              this.setScreen(ScreenEnum.Questions, true); //lastQuestionScreen);
            } else {
              if (this.q.isRenewal && this.q.useModifyFlowForRefills) this.setScreen(ScreenEnum.Modify);
              else if (this.q.noIdentityVerification || this.q.isRenewal) {
                if (!this.q.noProductReview) this.setScreen(ScreenEnum.Checkout);
                else this.setScreen(ScreenEnum.ShippingPaymentInfo);
              } else if (this.q.photoEntryAfterCheckout) {
                if (this.q.getStartedProductsAfterQuestionnaire) {
                  this.setScreen(ScreenEnum.GetStarted);
                } else this.setScreen(ScreenEnum.Checkout);
              } else {
                this.setScreen(ScreenEnum.IdentityUpload);
              }
            }
          } else {
            if (!forceScreenChange && lastQuestionScreen !== null) {
              this.setScreen(ScreenEnum.Questions, true); //lastQuestionScreen);
            } else {
              if (this.q.disableProductSelection || this.q.chooseProductPriorToAuth || this.q.getStartedProductsAfterQuestionnaire) {
                this.setScreen(ScreenEnum.BasicInfo);
              } else {
                if (skipShipping) this.setScreen(ScreenEnum.ChooseQuantity);
                else this.setScreen(ScreenEnum.ChooseShipping);
              }
            }
          }
          break;

        case ScreenEnum.QuestionsAfterCheckout:
          if (dir === "+") {
            if (!forceScreenChange && lastQuestionScreen !== null) this.setScreen(ScreenEnum.QuestionsAfterCheckout, true);
            else if (this.q.quest.visitTypeID == VisitType.Scheduled && !this.q.noDrReview) this.setScreen(ScreenEnum.ScheduleVisit);
            else if (this.q.photoEntryAfterCheckout && !this.q.isRenewal) this.setScreen(ScreenEnum.IdentityUpload);
            else this.setScreen(ScreenEnum.VisitComplete);
          } else {
            if (!forceScreenChange && lastQuestionScreen !== null) {
              this.setScreen(ScreenEnum.QuestionsAfterCheckout, true);
            }
          }
          break;

        case ScreenEnum.ChargeConfirmation:
          if (dir === "+")
            if (this.q.quest.visitTypeID == VisitType.Scheduled && !this.q.noDrReview) this.setScreen(ScreenEnum.ScheduleVisit);
            else {
              this.setScreen(ScreenEnum.VisitComplete);
              //this.recordConversion();
            }
          else {
            this.setScreen(ScreenEnum.ShippingPaymentInfo);
          }
          break;
        case ScreenEnum.IdentityUpload:
          if (dir === "+") {
            if (this.q.photoEntryAfterCheckout) {
              this.setScreen(ScreenEnum.VisitComplete);
            } else if (this.q.isRenewal && (this.q.chooseProductPriorToAuth || this.q.useModifyFlowForRefills)) {
              this.setScreen(ScreenEnum.Modify);
            } else if (this.q.getStartedProductsAfterQuestionnaire) {
              this.setScreen(ScreenEnum.GetStarted);
            } else {
              this.setScreen(ScreenEnum.Checkout);
              //TODO: if (this.q.currentOTCCart != null && this.q.currentOTCCart.length > 0) this.setScreen(ScreenEnum.OTCCart);
              //TODO: else if (this.q.disableProductSelection && !this.q.noProductReview) {
              //            this.setScreen(ScreenEnum.Checkout);
              //TODO: } else {
              //TODO:   this.setScreen(ScreenEnum.Checkout);
              //TODO: }
            }
          } else {
            if (this.q.photoEntryAfterCheckout) {
              this.setScreen(ScreenEnum.ScheduleVisit);
            } else this.setScreen(ScreenEnum.Questions);
          }
          break;

        case ScreenEnum.VisitComplete:
          if (dir === "+") {
            router.push("/patientportal");
          }
          break;
        case ScreenEnum.ScheduleVisit:
          if (dir === "+") {
            if (this.q.photoEntryAfterCheckout && !this.q.isRenewal) {
              this.setScreen(ScreenEnum.IdentityUpload);
            } else {
              this.setScreen(ScreenEnum.VisitComplete);
              //this.recordConversion();
            }
          }
          break;
        case ScreenEnum.OTCCart:
          /*if (dir === "+") this.setScreen(ScreenEnum.ShippingPaymentInfo);
          else this.setScreen(3);*/
          break;
        case ScreenEnum.ChooseMedication:
          if (dir === "+") {
            this.setScreen(ScreenEnum.ChooseQuantity);
          } else {
            this.setScreen(ScreenEnum.BasicInfo);
          }
          break;
        case ScreenEnum.ChooseQuantity:
          if (dir === "+") {
            if (skipShipping) this.setScreen(ScreenEnum.Questions);
            else this.setScreen(ScreenEnum.ChooseShipping);
          } else {
            if (this.q.isSingleProductGroup) {
              this.setScreen(ScreenEnum.BasicInfo);
            } else {
              this.setScreen(ScreenEnum.ChooseMedication);
            }
          }
          break;
        case ScreenEnum.ChooseShipping:
          if (dir === "+") {
            this.setScreen(ScreenEnum.Questions);
          } else {
            this.setScreen(ScreenEnum.ChooseQuantity);
          }
          break;
        case ScreenEnum.Modify:
          if (dir === "+") {
            this.setScreen(ScreenEnum.Checkout);
          } else {
            this.setScreen(ScreenEnum.Questions);
          }
          break;
        case ScreenEnum.Checkout:
          if (dir === "+") {
            if (this.q.showQuestionsAfterCheckout && this.q.quest.questionsObj.items.some((item) => item.isAfterCheckout === true && !item.isIgnoredByRuleset)) {
              this.setScreen(ScreenEnum.QuestionsAfterCheckout);
            } else if (this.q.quest.visitTypeID == VisitType.Scheduled && !this.q.noDrReview) this.setScreen(ScreenEnum.ScheduleVisit);
            else if (!this.q.visitRequired && this.q.photoEntryAfterCheckout && !this.q.isRenewal) this.setScreen(ScreenEnum.IdentityUpload);
            else {
              this.setScreen(ScreenEnum.VisitComplete);
              //this.recordConversion();
            }
          } else {
            //TODO: if (this.q.disableProductSelection) {
            if (this.q.isRenewal && this.q.useModifyFlowForRefills) this.setScreen(ScreenEnum.Modify);
            else if (this.q.noIdentityVerification || this.q.isRenewal) {
              this.setScreen(ScreenEnum.Questions);
            } else {
              if (this.q.getStartedProductsAfterQuestionnaire) this.setScreen(ScreenEnum.GetStarted);
              else this.setScreen(ScreenEnum.IdentityUpload);
            }
            //TODO: } else {
            //TODO:  if (skipShipping) this.setScreen(ScreenEnum.ChooseQuantity);
            //TODO:  else this.setScreen(ScreenEnum.ChooseShipping);
            //TODO: }
          }
          break;
        default:
        // do nothing
      }
      this.setLoading(false);
    },
    async getFreqsByProductID(id: number): Promise<ProductRxSubscriptionFrequencyViewModel[]> {
      var freqs = await store.dispatch("GetFreqsByProductRxID", id);
      if (freqs) {
        if (freqs.length > 0) {
          this.$emit("update:selectedProductSubscriptionAvailable", true);
        } else {
          this.$emit("update:selectedProductSubscriptionAvailable", false);
        }
      } else {
        this.$emit("update:selectedProductSubscriptionAvailable", false);
      }
      return freqs;
    },
    async pageLoadNavigate(isGetStarted: boolean) {
      if (this.q.quest && this.q.quest.lastScreen && this.q.quest.lastScreen > ScreenEnum.BasicInfo) {
        this.setScreen(this.q.quest.lastScreen);
      } else {
        if (isGetStarted) {
          if (!this.q.isRenewal) this.setScreen(ScreenEnum.GetStarted);
          else {
            if (this.q.useModifyFlowForRefills) this.setScreen(ScreenEnum.Modify);
            else this.setScreen(ScreenEnum.BasicInfo);
          }
        } else this.setScreen(ScreenEnum.BasicInfo);
      }
    },
    async saveandnavigate(dir: string, data: any, data2: any, data3: any, data4: any) {
      var screen = this.ensureScreen();
      var skipShipping = false;
      var arVM: AjaxReturnViewModel<QuestViewModel> | null = null;
      window.scrollTo(0, 0);
      this.setLoading(true);
      console.log(`saveandnavigate ${this.screen} ${dir}`);
      switch (this.screen) {
        case ScreenEnum.GetStarted:
          if (dir === "+") {
            // User comes in with get started at the beginning but they're signed in
            if (this.SignedIn && !this.q.getStartedProductsAfterQuestionnaire) {
              this.setLoading(true);
              // Get chosen productrx from GetStarted
              arVM = await store.dispatch("GetQuestionnaire", { t: this.QuestType });
              arVM.data.quest.qProductRxs = data.qProductRxs;
              data = arVM?.data.quest;
              this.setLoading(false);
            }
            data.lastScreen = screen;
            if (this.SignedIn) {
              await store.dispatch("SaveQuest", { q: data, t: this.QuestType });
            } else {
              // User comes in with get started at the beginning but they're not signed in
              // Just save locally
              var qVM = Object.assign({}, this.q);
              qVM.quest = data;
              this.setQuestionnaire({ q: qVM, t: this.QuestType });
            }
            this.q.quest = data;
          }
          this.navigate(dir, null, null, null, data2);
          break;
        case ScreenEnum.BasicInfo:
          await store.dispatch("SavePatient", { p: data, t: this.QuestType });
          this.q.quest.lastScreen = screen;
          this.q.quest.questionsObj.lastQuestionScreen = 0;
          await store.dispatch("SaveQuest", { q: this.q.quest, t: this.QuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.ShippingPaymentInfo:
          data2.lastScreen = screen;
          await store.dispatch("SavePatient", { p: data, quest: data2, t: this.QuestType });
          await store.dispatch("SaveQuest", { q: data2, t: this.QuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.IdentityUpload:
          await store.dispatch("SavePhotos", { p: data, t: this.QuestType });
          this.q.quest.lastScreen = screen;
          await store.dispatch("UpdateQuestLastScreen", { qtid: this.q.quest.questionnaireTypeID, ls: this.q.quest.lastScreen });
          this.navigate(dir);
          break;
        case ScreenEnum.Modify:
          data.lastScreen = screen;
          await store.dispatch("SaveQuest", { q: data, t: this.QuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.VisitComplete:
          this.navigate(dir);
          break;
        case ScreenEnum.Questions:
          var q = Object.assign({}, this.q.quest);
          q.questionsObj = data;
          var forceScreenChange = data2;
          q.hardStop = data3;
          skipShipping = data4;
          q.lastScreen = screen;
          await store.dispatch("SaveQuest", { q: q, t: this.QuestType, l: forceScreenChange }); //TODO: was sending forceScreenChange here for a reason
          this.navigate(dir, this.q.quest.questionsObj.lastQuestionScreen, forceScreenChange, skipShipping);
          break;
        case ScreenEnum.QuestionsAfterCheckout:
          this.q.quest.lastScreen = screen;
          var forceScreenChange2 = data2; // this is not practical (only added the 2 after because the variable `forceScreenChange` is already used above) but needs added for clarification instead of using solely `data2`.
          await store.dispatch("UpdateQuestLastScreen", { qtid: this.q.quest.questionnaireTypeID, ls: this.q.quest.lastScreen });
          this.q.quest.questionsObj.lastQuestionScreen = data.lastQuestionScreen; // this has to be updated otherwise it will use the lastQuestionScreen data from the questions before checkout.
          await store.dispatch("SaveQuestAfterCheckout", { q: this.q.quest, t: this.QuestType, l: forceScreenChange2 });
          this.navigate(dir, this.q.quest.questionsObj.lastQuestionScreen, forceScreenChange2, skipShipping);
          break;
        case ScreenEnum.Checkout:
          if (dir === "+") {
            data2.lastScreen = screen;
            await store.dispatch("SavePatient", { p: data, quest: data2, t: this.QuestType });
            await store.dispatch("SaveQuest", { q: data2, t: this.QuestType });
          }
          this.navigate(dir);
          break;
        case ScreenEnum.ChargeConfirmation:
          if (dir === "+") {
            this.q.quest.lastScreen = screen;
            await store.dispatch("SavePatient", { p: data, quest: this.q.quest, t: this.QuestType });
            await store.dispatch("SaveQuest", { q: this.q.quest, t: this.QuestType });
          }
          this.navigate(dir);
          break;
        case ScreenEnum.ScheduleVisit:
          this.q.quest.lastScreen = screen;
          await store.dispatch("SaveQuest", { q: this.q.quest, t: this.QuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.OTCCart:
          /*this.CurrentOTCCart = data;
          this.saveQuest(this.Quest, function () {
            this.saveOTCCart(this.CurrentOTCCart, function () {
              this.navigate(dir);
            });
          });*/
          break;
        case ScreenEnum.ChooseMedication:
          data.lastScreen = screen;
          await store.dispatch("SaveQuest", { q: data, t: this.QuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.ChooseQuantity:
          skipShipping = data2;
          data.lastScreen = screen;
          await store.dispatch("SaveQuest", { q: data, t: this.QuestType });
          this.navigate(dir, null, null, skipShipping);
          break;
        case ScreenEnum.ChooseShipping:
          data.lastScreen = screen;
          await store.dispatch("SaveQuest", { q: data, t: this.QuestType });
          this.navigate(dir);
          break;
        case ScreenEnum.ProductReview:
          skipShipping = data2;
          data.lastScreen = screen;
          await store.dispatch("SaveQuest", { q: data, t: this.QuestType });
          this.navigate(dir, null, null, skipShipping);
          break;
        case ScreenEnum.SignUp:
          var eq = this.q && this.q.quest ? Object.assign({}, this.q.quest) : null;
          arVM = await store.dispatch("GetQuestionnaire", { t: this.QuestType });
          if (!arVM || !arVM.success || !arVM.data || !arVM.data.quest) {
            this.setLoading(false);
            router.push("/questionnaire/error");
            return;
          }
          // Get chosen productrx from GetStarted
          if (eq) {
            arVM.data.quest.qProductRxs = eq.qProductRxs;
            this.setQuestionnaire({ q: arVM.data, t: this.QuestType });
          }
          this.navigate(dir);
          break;
        default:
        // do nothing
      }
    },
    setScreen(s: number | ScreenEnum, noPush?: boolean) {
      console.log(`setScreen ${s}`);
      if (s !== ScreenEnum.Questions && s === this.screen) return s;
      // If we've already paid but need to schedule, make sure we only can schedule
      if (this.q && this.q.quest && this.q.quest.statusID == 2 && this.screen != ScreenEnum.VisitComplete && this.screen != ScreenEnum.ScheduleVisit) {
        this.q.quest.lastScreen = ScreenEnum.ScheduleVisit;
        if (this.q.quest.visitTypeID == VisitType.Scheduled) s = ScreenEnum.ScheduleVisit;
      }

      if (this.q && this.q.quest) {
        var q = Object.assign({}, this.q);
        q.quest.lastScreen = s;
        this.setQuestionnaire({ q: q, t: this.QuestType });
      }
      this.screen = s;

      if (noPush) {
        //history.pushState({}, "", document.location.href.split("#")[0] + "#" + s + ":" + qs);
      } else {
        switch (s) {
          case ScreenEnum.GetStarted:
            router.push("/questionnaire/getstarted");
            break;
          case ScreenEnum.SignUp:
            router.push("/questionnaire/signup");
            break;
          case ScreenEnum.BasicInfo:
            router.push("/questionnaire/basicinfo");
            break;
          case ScreenEnum.ChooseMedication:
            router.push("/questionnaire/choosemedication");
            break;
          case ScreenEnum.ChooseQuantity:
            router.push("/questionnaire/choosequantity");
            break;
          case ScreenEnum.ChooseShipping:
            router.push("/questionnaire/chooseshipping");
            break;
          case ScreenEnum.ProductReview:
            router.push("/questionnaire/productreview");
            break;
          case ScreenEnum.Questions:
            this.isAfterCheckout = false;
            router.push("/questionnaire/questions");
            break;
          case ScreenEnum.QuestionsAfterCheckout:
            this.isAfterCheckout = true;
            router.push("/questionnaire/aftercheckoutquestionnaire");
            break;
          case ScreenEnum.IdentityUpload:
            router.push("/questionnaire/identityupload");
            break;
          case ScreenEnum.ShippingPaymentInfo:
            router.push("/questionnaire/shippingpaymentinfo");
            break;
          case ScreenEnum.ChargeConfirmation:
            router.push("/questionnaire/chargeconfirmation");
            break;
          case ScreenEnum.VisitComplete:
            router.push("/questionnaire/visitcomplete");
            break;
          case ScreenEnum.ScheduleVisit:
            router.push("/questionnaire/schedulevisit");
            break;
          case ScreenEnum.Error:
            router.push("/questionnaire/error");
            break;
          case ScreenEnum.NotYet:
            router.push("/questionnaire/sorry");
            break;
          case ScreenEnum.Checkout:
            router.push("/questionnaire/checkout");
            break;
          case ScreenEnum.Modify:
            router.push("/questionnaire/modify");
            break;
          default:
            break;
        }
      }
      this.setLoading(false);
      return s;
    },
  },
  components: {
    BContainer,
    BRow,
    BCol,
  },
});
