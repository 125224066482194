
import mixins from "vue-typed-mixins";
import CustomValidation from "../mixins/CustomValidation";
import common from "../mixins/Common";
import { mapGetters, mapMutations } from "vuex";
import store from "../store";
import { BIcon } from "bootstrap-vue";
import { PatientPortalRxContainer } from "../interfaces/PatientPortalRxContainer";
import { PatientPortalRx } from "@/interfaces/PatientPortalRx";
import swal from "sweetalert";
import { number } from "yup";

export default mixins(CustomValidation, common).extend({
  name: "rx-history-component",
  mixins: [common],
  props: {
    patientuserid: { type: Number, default: null },
    patientaffiliateid: { type: Number, default: null },
  },
  data() {
    return {
      rxs: [] as PatientPortalRxContainer[],
      isCollapsed: [] as { [key: number]: boolean }[],
      openToggle: true as boolean,
    };
  },
  async mounted() {
    await this.getRxHistoryByUserID();
  },

  watch: {
    patientuserid: {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal && oldVal != newVal) {
          this.getRxHistoryByUserID();
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      Affiliate: "getAffiliate",
      Loading: "getLoading",
      Content: "getContent",
    }),
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setQuestionnaire: "setQuestionnaire",
    }),

    async getRxHistoryByUserID() {
      const result = await store.dispatch("GetRxHistoryByUserID", {
        patientUserID: this.patientuserid,
        patientAffiliateID: this.patientaffiliateid,
      });
      if (!result || !result.data || result.data.length === 0) {
        this.$bvToast.toast("An error has occurred!", {
          title: "Error",
          autoHideDelay: 3000,
          appendToast: true,
        });
        return;
      }
      result.data.sort((a, b) => b.questionnaireID - a.questionnaireID);
      result.data.forEach((x) => x.rxs.sort((a, b) => this.getContentJSONOrder(a) - this.getContentJSONOrder(b)));
      this.rxs = result.data;
    },

    getShowDirections(rx: PatientPortalRx): boolean | null {
      if (rx.apRxContentJSON) {
        return JSON.parse(rx.apRxContentJSON).ShowDirections;
      }
      return null;
    },
    getRxTitleOverride(rx: PatientPortalRx): string | null {
      if (this.Role === "Physician") {
        let result = rx.drugDisplayName;
        result += rx.drugStrength ? ` Strength ${rx.drugStrength}` : "";
        result += rx.unitsPerDose ? ` Units Per Dose ${rx.unitsPerDose}` : "";
        return result;
      }
      if (rx.apRxContentJSON) {
        return JSON.parse(rx.apRxContentJSON).RxTitleOverride;
      }
      return null;
    },
    getGroupRxFooter(rx: PatientPortalRx): string | null {
      if (rx.apRxContentJSON) {
        return JSON.parse(rx.apRxContentJSON).GroupRxFooter;
      }
      return null;
    },
    getContentJSONOrder(rx: PatientPortalRx): number {
      if (rx.apRxContentJSON) {
        return isNaN(JSON.parse(rx.apRxContentJSON).PatientPortalOrder) ? 9999 : parseInt(JSON.parse(rx.apRxContentJSON).PatientPortalOrder);
      }
      return 9999;
    },
    nameIconToggle(ID: number, isAfterFirstItem: boolean) {
      if (this.isCollapsed[ID] === undefined) {
        this.$set(this.isCollapsed, ID, isAfterFirstItem);
      }
      this.$set(this.isCollapsed, ID, !this.isCollapsed[ID]);
    },
    Track(rx: PatientPortalRx) {
      swal("Warning", "You are about to leave our site and navigate to a shipping carrier site.", "warning").then(() => {
        window.open(rx.trackingString.replace("{0}", rx.trackingNumber), "_blank");
      });
    },
  },
  components: { BIcon },
});
