import { render, staticRenderFns } from "./RxHistoryVueComponent.vue?vue&type=template&id=66ae9e79&"
import script from "./RxHistoryVueComponent.vue?vue&type=script&lang=ts&"
export * from "./RxHistoryVueComponent.vue?vue&type=script&lang=ts&"
import style0 from "./RxHistoryVueComponent.vue?vue&type=style&index=0&id=66ae9e79&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports